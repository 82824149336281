<template>
  <b-card class="border-secondary">
    <div class="emissao-card-header d-flex align-items-end mb-2">
      <b-img :src="emissaoImage(emission)" class="mr-1" heigth="77" width="77" />
      <div class="d-flex flex-column overflow-auto">
        <h4
          class="truncate-text"
          v-b-tooltip.hover
          :title="`${emission.tipo_emissao} - ${emission.nome_fantasia}`"
        >
          {{ emission.tipo_emissao }} - {{ emission.nome_fantasia }}
        </h4>
        <span>{{ emission.valor_global_emissao | moneyFormat }}</span>
      </div>
    </div>
    <div class="emissao-card-data">
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span> Status </span>
        <b-badge
          :class="`badge badge-light-${emission.is_ativo ? 'success' : 'danger'} border-${
            emission.is_ativo ? 'success' : 'danger'
          }`"
        >
          <span class="text-dark">
            {{ emission.is_ativo ? 'Ativo' : 'Inativo' }}
          </span>
        </b-badge>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span> Emissão </span>
        <strong> {{ emission.numero_emissao }} </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span> Data da Emissão </span>
        <strong> {{ emission.data_emissao | formatDate }} </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span> Data do Vencimento </span>
        <strong> {{ emission.data_vencimento | formatDate }} </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span class="text-nowrap mr-1"> Código IF </span>
        <strong class="truncate-text" v-b-tooltip.hover :title="emission.codigo_cetip">
          {{ emission.codigo_cetip }}
        </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span class="text-nowrap mr-1"> ISIN </span>
        <strong class="truncate-text" v-b-tooltip.hover :title="emission.codigo_isin">
          {{ emission.codigo_isin }}
        </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span class="text-nowrap mr-1"> Emissor </span>
        <strong class="truncate-text" v-b-tooltip.hover :title="emission.emissor">
          {{ emission.emissor }}
        </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span class="text-nowrap mr-1"> Agente Fiduciário </span>
        <strong class="truncate-text" v-b-tooltip.hover :title="emission.agente_fiduciario">
          {{ emission.agente_fiduciario }}
        </strong>
      </div>
      <div class="d-flex justify-content-between border-top pt-50 pb-50">
        <span class="text-nowrap mr-1"> Remuneração </span>
        <strong class="truncate-text" v-b-tooltip.hover :title="emission.remuneracao">
          {{ emission.remuneracao }}
        </strong>
      </div>
    </div>
    <div class="emissao-card-action">
      <b-button
        variant="primary"
        class="emissoes-details-button w-100 border-primary"
        @click="$router.push({ name: 'assignor.details', params: { serie_id: emission.serie_id } })"
      >
        <span class="text-dark d-flex justify-content-center align-items-center font-weight-bolder">
          Detalhes
          <feather-icon icon="ArrowRightIcon" size="20" class="ml-1" />
        </span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg, BBadge, BButton, VBTooltip } from 'bootstrap-vue'
import { moneyFormat } from '@/@core/comp-functions/data_visualization/datatable'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'EmissionCard',
  components: {
    BCard,
    BImg,
    BBadge,
    BButton,
    VBTooltip,
  },
  props: {
    emission: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    moneyFormat,
    formatDate,
  },
  methods: {
    emissaoImage(emissao) {
      const options = {
        cri: 'cri',
        cra: 'cra',
        deb: 'debenture',
        nc: 'nota_comercial',
      }

      if (!emissao.tipo_emissao || !options[emissao.tipo_emissao.toLowerCase()]){
        return require('@/assets/images/recebíveis.svg')
      }

      return require(`@/assets/images/${options[emissao.tipo_emissao.toLowerCase()]}.svg`)
    },
  },
}
</script>
